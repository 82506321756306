import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import OrderImage from "./OrderImage";
import { OrangeButton } from "../views/PhaseBStyles/style";
import { chackAndDeleteCart } from "../views/Cart/Cart/action";
import { useDispatch } from "react-redux";

export function CartDeleteDialog(props) {
  const {
    open,
    setOpen,
    cartItems
  } = props;

  const dispatch = useDispatch()

  const history = useHistory()

  const handleClick = () => {
    setOpen(!open)
    dispatch(chackAndDeleteCart())
    history.push("/cart")
  }

  return (
    <Box>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          以下の部品は完売しました。
        </DialogTitle>
        <DialogContent>
          {cartItems &&
            cartItems?.map((product) => (
              <>
                <Grid
                  container
                  key={product.item_id}
                  spacing={2}
                  sx={{ pt: 2 }}
                >
                  <Grid item xs={5}>
                    <OrderImage
                      responsive={"responsive-cart"}
                      imgPath={product.path_to_photo_storage ?? ""}
                      productId={product.item_id}
                      history={history}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      component="p"
                      variant="p"
                      sx={{ marginTop: { xs: "2px", md: "5px" } }}
                      className="font-size-18"
                    >
                      {product.item_title}
                    </Typography>
                    {product.color_code ? (
                      <Typography component="p" variant="p" mt="8px" className="font-size-14 opacity-80">
                        カラー番号 : {product.color_code}
                      </Typography>
                    ) : null}
                    <Typography component="p" variant="p" mt="4px" className="font-size-14 opacity-80">
                      出品者 : {"株式会社パーツワン"}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
        </DialogContent>
        <DialogActions>
          <OrangeButton
            sx={{
              margin: 1,
              padding: 2
            }}
            className="font-size-16 font-weight-700"
            onClick={handleClick}
          >
            カートに戻る
          </OrangeButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}