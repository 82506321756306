import aws from "aws-sdk"

const access_key = process.env.REACT_APP_AWS_ACCESS_KEY
const secret_key = process.env.REACT_APP_AWS_SECRET_KEY
const region = process.env.REACT_APP_AWS_REGION
const bucket = process.env.REACT_APP_AWS_BUCKET

const s3 = new aws.S3({
    accessKeyId: access_key,
    secretAccessKey: secret_key,
    region: region,
    signatureVersion: "v4",
});

export async function AWSImageUploader(imageName) {

    const s3Params = {
        Bucket: bucket,
        Key: imageName,
        Expires: 10000,
    };

    const uploadUrl = await s3.getSignedUrlPromise(
        "putObject",
        s3Params,
    );

    return uploadUrl
}

export async function AWSImageReader(imageName) {
    const s3Params = {
        Bucket: bucket,
        Key: imageName,
        Expires: 10000,
    }
    const getUrl = await s3.getSignedUrlPromise(
        "getObject",
        s3Params,
    );

    return getUrl
}

