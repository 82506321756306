export const GET_INFO_DZONE_MY_PAGE_SAGA = "view/MyPage/MyPage/GET_INFO_DZONE_MY_PAGE_SAGA";
export const GET_INFO_DZONE_MY_PAGE__REDUCER = "view/MyPage/MyPage/GET_INFO_DZONE_MY_PAGE__REDUCER";
export const INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER =
  "view/MyPage/MyPage/INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER";
export const MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER =
  "view/MyPage/MyPage/MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER";
export const UPDATE_INCHARGE_A22 = "view/MyPage/MyPage/UPDATE_INCHARGE_A22";
export const POST_INCHARGE_A22 = "view/MyPage/MyPage/POST_INCHARGE_A22";
export const UPDATE_INCHARGE_A22_REDUCER = "view/MyPage/MyPage/UPDATE_INCHARGE_A22_REDUCER";
export const DELETE_INCHARGE_A22_A12 = "view/MyPage/MyPage/DELETE_INCHARGE_A22_A12";
export const DELETE_INCHARGE_A22_A12_REDUCER = "view/MyPage/MyPage/DELETE_INCHARGE_A22_A12_REDUCER";
export const GET_INFO_DZONE_INCHARGE_LIST_SAGA =
  "view/MyPage/MyPage/GET_INFO_DZONE_INCHARGE_LIST_SAGA";
export const GET_INFO_DZONE_INCHARGE_LIST_REDUCER =
  "view/MyPage/MyPage/GET_INFO_DZONE_INCHARGE_LIST_REDUCER";
export const GET_INFO_DZONE_SHIPPING_LIST_SAGA =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_SAGA";
export const GET_INFO_DZONE_SHIPPING_LIST_REDUCER =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_REDUCER";
export const GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET";
export const GET_INFO_DZONE_INCHARGE_LIST_REDUCER_RESET =
  "view/MyPage/MyPage/GET_INFO_DZONE_INCHARGE_LIST_REDUCER_RESET";
export const UPDATE_ERROR_MESSAGE = "view/MyPage/MyPage/UPDATE_ERROR_MESSAGE";
export const PUT_SHIPPING_AS_DEFAULT = "view/MyPage/MyPage/PUT_SHIPPING_AS_DEFAULT";
