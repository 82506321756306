import {
  GET_INFO_DZONE_MY_PAGE_SAGA,
  MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER,
  INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER,
  GET_INFO_DZONE_INCHARGE_LIST_SAGA,
  PUT_SHIPPING_AS_DEFAULT,
  DELETE_INCHARGE_A22_A12,
} from "./constants";
/* Login Action mapping for login API call */
export function getMyPageInfo(payload) {
  return {
    type: GET_INFO_DZONE_MY_PAGE_SAGA,
    payload: payload,
  };
}

export function getInChargeList(payload) {
  return {
    type: GET_INFO_DZONE_INCHARGE_LIST_SAGA,
    payload: payload,
  };
}

export function deleteInChargeList(payload) {
  return {
    type: DELETE_INCHARGE_A22_A12,
    payload: payload,
  };
}

export function setMyPageInfoProfile(payload) {
  return {
    type: INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER,
    payload: payload,
  };
}

export function myPageSpTopView() {
  return {
    type: MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER,
  };
}

export function putShippingAsDefault(payload) {
  return {
    type: PUT_SHIPPING_AS_DEFAULT,
    payload: payload,
  };
}
