import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

import { ToastProvider } from "react-toast-notifications";
import ReduxToastr from "react-redux-toastr";

import { Provider } from "react-redux";
import store from "./store";

// Toast ( Customized )
import { MyCustomToast } from "./components/Toast/MyCustomToast";

import TagManager from "react-gtm-module";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
}

store.subscribe(() => saveToLocalStorage(store.getState()));

if (screen.lockOrientation) {
  window.screen.orientation.lock("portrait");
}

const gtmId = process.env.REACT_APP_GTM_KEY;
const ga4Id = process.env.REACT_APP_G_KEY;

// Google Tag Managerの初期化
TagManager.initialize({
  gtmId: gtmId,
});

// Google Analytics 4の初期化
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", ga4Id);

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  // document.getElementById('root')
  <Provider store={store}>
    <ToastProvider components={{ Toast: MyCustomToast }}>
      <ReduxToastr
        timeOut={2750}
        newestOnTop={false}
        preventDuplicates
        position="bottom-left"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <App />
    </ToastProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
