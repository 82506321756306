import {
  GET_INFO_DZONE_CART_INFO_C1_SAGA,
  FETCH_CART_INFO_SAGA,
  GET_ITEM_POSTAGE_SAGA,
  GET_ORDER_API,
  UPDATE_ORDER_API,
  DELETE_CART_API,
} from "./constants";
/* Login Action mapping for login API call */
export function getcartInfo(payload) {
  return {
    type: GET_INFO_DZONE_CART_INFO_C1_SAGA,
    payload: payload,
  };
}
export function fetchCartInfo(payload) {
  return {
    type: FETCH_CART_INFO_SAGA,
    payload: payload,
  };
}
export function getPostage(payload) {
  return {
    type: GET_ITEM_POSTAGE_SAGA,
    payload: payload,
  };
}
export function getOrder(payload) {
  return {
    type: GET_ORDER_API,
    payload: payload,
  };
}
export function updateOrder(payload) {
  return {
    type: UPDATE_ORDER_API,
    payload: payload,
  };
}

export function chackAndDeleteCart() {
  return {
    type: DELETE_CART_API,
  };
}
