import React, { useEffect, useState } from "react";
import "./addCardDetails.css";
import "../../views/PhaseCStyles/style.css";
import loadingForever from "../../assets/gif/loading-loading-forever.gif";
import { OrangeButton } from "../../views/PhaseBStyles/style";
import { Grid, Stack } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useLocation } from "react-router-dom";

export function LoadingView({ isLoading, isValid, submit, close }) {
  return isLoading ? (
    <React.Fragment>
      <div className="font-size-18 font-weight-700 opacity-80 text-center ">カードを確認中</div>
      <div className="mb-4 mt-4 text-center">
        <img
          src={loadingForever}
          width="44px"
          height="44px"
          alt="loadign image"
          style={{ background: "transparent", width: "44px", height: "44px" }}
        />
      </div>

      <div className="process-failed">
        <OrangeButton className="process-failed" onClick={close} sx={{ mb: 1 }}>
          <span className="font-size-16 font-weight-700">確認する</span>
        </OrangeButton>
      </div>
    </React.Fragment>
  ) : (
    <div>
      <OrangeButton
        variant="contained"
        onClick={submit}
        sx={{ mt: 7, mb: 2 }}
        fullWidth
        className="font-weight-700"
      >
        確認する
      </OrangeButton>
    </div>
  );
}

export function ProcessViewHeader({ isLoading, isProccessCompleted }) {
  return isLoading == false && isProccessCompleted == true ? (
    <Grid item md={12} sm={12} xs={12} spacing={2} sx={{ mt: 2, mb: 1 }}>
      <Stack
        spacing={2}
        lg={12}
        xs={12}
        sm={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ m: 0 }}
        className="product-suggestion"
      >
        <Grid item md={12} xs={10} sm={10}>
          <div className="text-center font-size-18 font-weight-700 opacity-80">
            カードが追加されました
          </div>
          <div className="text-center">
            <CheckCircleOutlineRoundedIcon
              className="process-completed"
              sx={{ fontSize: 40, mt: 1 }}
            />{" "}
          </div>
        </Grid>
      </Stack>
    </Grid>
  ) : isLoading == false && isProccessCompleted == false ? (
    <Grid item md={12} sm={12} xs={12} spacing={2} sx={{ mt: 2, mb: 1 }}>
      <Stack
        spacing={2}
        lg={12}
        xs={12}
        sm={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ m: 0 }}
        className="product-suggestion"
      >
        <Grid item md={12} xs={10} sm={10}>
          <div className="text-center font-size-18 font-weight-700 opacity-80">
            エラーが発生しました
          </div>
          <div className="text-center">
            <CancelOutlinedIcon className="process-failed" sx={{ fontSize: 40, mt: 1 }} />{" "}
          </div>
        </Grid>
      </Stack>
    </Grid>
  ) : null;
}

export function ProcessView({ isProccessCompleted, refresh, submit }) {
  const location = useLocation();
  const urlName = location.pathname;

  return (
    <React.Fragment>
      <div className="process-failed">
        {isProccessCompleted ? (
          <OrangeButton
            fullWidth
            sx={{
              mt: 6,
              my: 1,
            }}
            onClick={refresh}
          >
            <span className="font-size-16 font-weight-700">
              {urlName == "/checkout" ? "購入画面に戻る" : "閉じる"}
            </span>
          </OrangeButton>
        ) : (
          <React.Fragment>
            <OrangeButton
              variant="contained"
              fullWidth
              sx={{
                mt: 1,
                my: 1,
              }}
              onClick={submit}
            >
              <span className="font-size-16 font-weight-700">登録する</span>
            </OrangeButton>
            <OrangeButton
              sx={{
                color: "white",
                bgcolor: "#E02D2D",
                mt: 1,
                my: 1,
                py: 3.5,
              }}
              variant="contained"
              onClick={refresh}
            >
              {urlName == "/checkout" ? (
                <span style={{ fontSize: "0.8rem" }} className="font-weight-700">
                  <>購入画面に戻る</>
                </span>
              ) : (
                <span style={{ fontSize: "0.6rem" }} className="font-weight-700">
                  クレジットカード詳細
                  <br />
                  画面に戻る
                </span>
              )}
            </OrangeButton>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
