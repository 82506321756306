import instance from "../../utils/AxiosIntance";
import { GET_NOTICE_DETAIL_SAGA, GET_NOTICE_DETAIL } from "./constants";
import _ from "lodash";
import { GET_ORDER_HISTORY_DETAIL_API } from "../../utils/apiConstants";
import { toastr } from "react-redux-toastr";
import { put, takeLatest, call } from "redux-saga/effects";

function getNoticePartsAPICall(payload) {
  // isLoading(true);
  return instance
    .get(GET_ORDER_HISTORY_DETAIL_API, {
      params: payload.payload,
    })
    .then((response) => {
      //   isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      //   isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getNoticeParts = function* (payload) {
  let response;
  try {
    response = yield call(getNoticePartsAPICall, payload);
    yield put({
      type: GET_NOTICE_DETAIL_SAGA,
      payload: { payload: response },
    });
  } catch (error) {
    console.error(error);
  }
};

function* NoticeDetailSaga() {
  yield takeLatest(GET_NOTICE_DETAIL, getNoticeParts);
}

export default NoticeDetailSaga;
