import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      /* Generic  start*/
      "app.generic.noitem": "No items",
      "app.fields.required": "Required fields. Please enter the text.",
      "app.validation.required.email": "Mail Address required.",
      "app.validation.max100": "Maximum 100 characters allowed",
      "app.validation.max1000": "Maximum 1000 characters allowed",
      "app.validation.max50": "Maximum 50 characters allowed",
      "app.validation.email": "Invalid Email ID",
      "app.validation.required.password": "Please set the password more than 8 characters",
      "app.validation.required.company_name": "Please provide company name",
      "app.validation.required.signupcode": "Please enter code",
      "app.validation.nospecialchar": "Special char not allowed",
      "app.validation.signupcode": "Invalid Code",
      "app.validation.lenght.password": "Password must be at least 8 characters",
      "app.validation.length.password": "8 to 30 single-byte alphanumerical characters.",
      "app.validation.lenght.password.same": "Please enter the same password",
      "app.validation.digits.max7": "Please enter in 7 digits",
      "app.validation.phoneNumber": "Phone number",
      "app.validation.numbers": "Only half-width numbers can be entered",
      "app.validation.select.pullDown": "Required fields. Please select a pull-down.",
      "app.validation.min4": "Min 4 characters are required",
      "app.validation.max4": "Max 4 characters are allowed",
      "app.validation.min5": "Min 5 characters are required",
      "app.validation.max5": "Max 5 characters are allowed",
      "app.validation.min6": "Min 6 characters are required",
      "app.validation.max7": "Max 7 characters are allowed",
      "app.validation.max30": "Max 30 characters are allowed",
      "app.validation.password.english": "Please use 8 to 30 half-width alphanumeric characters",
      "app.validation.alphanumeric.characters": "8 to 30 alphanumeric characters",
      /* Give your choose yourself end */
    },
  },
  ja: {
    translation: {
      /* Generic  start*/
      "app.generic.noitem": "表示するものはありません。",
      "app.fields.required": "必須項目です。テキストを入力してください。",
      "app.validation.required.email": "メールアドレスが必要です。",
      "app.validation.max100": "入力可能な文字数を超えています。最大文字数：100",
      "app.validation.max1000": "入力可能な文字数を超えています。最大文字数：1000",
      "app.validation.max50": "入力可能な文字数を超えています。最大文字数：50",
      "app.validation.email": "メールアドレスの形式で入力してください。",
      "app.validation.required.password": "必須項目です。テキストを入力してください。",
      "app.validation.required.company_name": "事業者名を入力してください",
      "app.validation.required.signupcode": "コードが必要です。",
      "app.validation.nospecialchar": "特殊文字使用不可。",
      "app.validation.signupcode": "5桁で入力してください",
      "app.validation.lenght.password":
        "半角英字と数字を含めて8文字以上30文字以内で入力してください。",
      "app.validation.length.password":
        "半角英字と数字を含めて8文字以上30文字以内で入力してください。",
      "app.validation.lenght.password.same": "同じパスワードを入力してください",
      "app.validation.required.inquiry.type": "お問い合わせ種類が必要です。     ",
      "app.validation.required.inquiry.content": "お問い合わせ内容が必要です。 ",
      "app.domo.payment.validation.add.card.number": "・クレジットカードの番号が正しくありません",
      "app.domo.payment.validation.add.card.expire.month":
        "・クレジットカードの有効期限をご確認のうえ、入力しなおしてください",
      "app.domo.corporate.invalid.expire.mounth":
        "・クレジットカードの有効期限をご確認のうえ、入力しなおしてください",
      "app.validation.lenght.card.number": "カード番号は 14 ~ 16 桁である必要があります",
      "app.validation.phone": "電話番号の形式で入力してください",
      "app.validation.fax": "FAXの形式で入力してください",
      "app.validation.passwordLength":
        "半角英字と数字を含めて8文字以上30文字以内で入力してください。",
      "app.validation.digits.max7": "7桁で入力くしてください",
      "app.validation.phoneNumber": "電話番号",
      "app.validation.numbers": "入力可能な文字は半角数字のみです",
      "app.validation.select.pullDown": " 必須項目です。プルダウンを選択してください。",
      "app.validation.min4": "最小4文字が必要です",
      "app.validation.max4": "最大4文字まで使用できます",
      "app.validation.min5": "最小5文字が必要です",
      "app.validation.max5": "最大5文字まで使用できます",
      "app.validation.min6": "最小6文字が必要です",
      "app.validation.max6": "最大6文字まで使用できます",
      "app.validation.max7": "最大7文字まで使用できます",
      "app.validation.min7": "最小7文字が必要です",
      "app.validation.max30": "最大30文字まで使用できます",
      "app.validation.password.english":
        "半角英字と数字を含めて8文字以上30文字以内で入力してください。",
      "app.validation.alphanumeric.characters":
        "半角英字と数字を含めて8文字以上30文字以内で入力してください。",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ja", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
