import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_INFO_DZONE_CART_INFO_C1_SAGA,
  FETCH_CART_INFO_SAGA,
  GET_INFO_DZONE_CART_INFO_C1_REDUCER,
  GET_INFO_DZONE_CART_INFO_DEL_C1_SAGA,
  GET_INFO_DZONE_CART_INFO_DEL_C1_REDUCER,
  GET_INFO_DZONE_CART_INFO_USER_C2_SAGA,
  GET_INFO_DZONE_CART_INFO_USER_C2_REDUCER,
  C2_DELETE_DELIVERY_DATE_LIST_REDUCER,
  CHECKOUT_CART_SAGA,
  C2_GET_DELIVERY_DATE_LIST_REDUCER,
  C2_GET_DELIVERY_DATE_LIST,
  GET_ITEM_POSTAGE_REDUCER,
  GET_ITEM_POSTAGE_SAGA,
  GET_ORDER_API,
  PUT_ORDER_API,
  UPDATE_ORDER_API,
  DELETE_CART_API,
} from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  GET_CART_INFO_API,
  GET_CART_INFO_DEL_API,
  GET_CART_USER_INFO_API,
  POST_CART_API,
  GET_DELV_LIST_API,
  GET_POSTAGE_API,
  GET_ORDERDET_API,
  GET_CART_INFO_UPDATE_API,
  DELETE_CART_INFO_DELETE_API,
} from "../../../utils/apiConstants";
import instance from "../../../utils/AxiosIntance";
import { getCookie } from "../../../utils/cookieUtils";

function userCartInfoHandler(params, isLoading) {
  return instance
    .get(GET_CART_INFO_API, { params: params })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

/* API calls END  */
const getCartInfoHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  let response;
  isLoading(true);
  try {
    response = yield call(userCartInfoHandler, payload.payload.params, isLoading);
    if (_.get(response, "result", false)) {
      if (getCookie("access_token")) {
        const flg = _.get(response, "data");
        if (flg.length && !flg[0].items.length) {
          history.push("cart/empty");
        }
      } else {
        history.push("/cart/no-login-empty");
      }
      yield put({
        type: GET_INFO_DZONE_CART_INFO_C1_REDUCER,
        payload: {
          data: _.get(response, "data"),
        },
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("Something went wrong");
  } finally {
    isLoading(false);
  }
};

const fetchCartInfoSaga = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    isLoading(true);
    response = yield call(userCartInfoHandler, payload.payload.params, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_CART_INFO_C1_REDUCER,
        payload: {
          data: _.get(response, "data"),
        },
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("Something went wrong");
  } finally {
    isLoading(false);
  }
};

function userCartInfoDelHandler(params, isLoading) {
  return instance
    .delete(GET_CART_INFO_DEL_API, { params: params })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getCartInfoDeleteHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  let response;
  isLoading(true);
  try {
    response = yield call(userCartInfoDelHandler, payload.payload.params, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_CART_INFO_DEL_C1_REDUCER,
        payload: Math.random() * 1000,
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("Something went wrong");
  } finally {
    isLoading(false);
  }
};

function userCartInfoUserHandler(params, isLoading) {
  return instance
    .get(GET_CART_USER_INFO_API)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getCartInfoUserHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  let response;
  isLoading(true);
  try {
    response = yield call(userCartInfoUserHandler, payload.payload.params, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_CART_INFO_USER_C2_REDUCER,
        payload: {
          data: _.get(response, "data"),
        },
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("Something went wrong");
  } finally {
    isLoading(false);
  }
};

function postCartConfirmHandler(params, isLoading) {
  return instance
    .post(POST_CART_API, params)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const postCartHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  isLoading(true);

  try {
    const response = yield call(postCartConfirmHandler, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      history.replace({
        pathname: "/checkout/complete",
        state: {
          stripe_card_id: payload.payload.data.stripe_card_id,
          response: _.get(response, "result", false),
          order_id: response.data.order_id,
          gtmData: payload.payload.gtmData,
        },
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("Something went wrong");
  } finally {
    isLoading(false);
  }
};

function getDeliveryListFunction(param) {
  return instance
    .get(GET_DELV_LIST_API, { params: param })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getDeliveryListHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  let response;
  isLoading = true;
  try {
    response = yield call(getDeliveryListFunction, payload.payload.data);
    if (_.get(response, "result", true)) {
      yield put({
        type: C2_GET_DELIVERY_DATE_LIST_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("Something went wrong");
  } finally {
    isLoading = false;
  }
};

function getItemPostageApiCall(payload) {
  return instance
    .get(GET_POSTAGE_API, { params: payload.payload })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}
const itemPostage = function* (payload) {
  let response;
  try {
    response = yield call(getItemPostageApiCall, payload.payload);
    if (_.get(response, "result", true)) {
      yield put({
        type: GET_ITEM_POSTAGE_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("Something went wrong");
  }
};

function getOrderApiCall() {
  return instance
    .get(GET_ORDERDET_API)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}
const orderDetails = function* () {
  let response;
  try {
    response = yield call(getOrderApiCall);
    yield put({
      type: PUT_ORDER_API,
      payload: _.get(response, "result"),
    });
  } catch (error) {
    toastr.error("Something went wrong");
  }
};

function updateOrderApiCall(payload) {
  return instance
    .put(GET_CART_INFO_UPDATE_API, { items: payload.data.items })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const updateOrderDetails = function* (payload) {
  const response = yield call(updateOrderApiCall, payload.payload);
  if (_.get(response, "result", true)) {
    //
  } else {
    toastr.error("", _.get(response, "message"));
  }
};

function deleteOrderApiCall() {
  return instance
    .delete(DELETE_CART_INFO_DELETE_API)
    .then((response) => {
      return _.get(response, "data");
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const chackAndDeleteCart = function* () {
  const response = yield call(deleteOrderApiCall);
  try {
    yield put({
      type: C2_DELETE_DELIVERY_DATE_LIST_REDUCER,
      payload: response,
    });
  } catch (e) {}
};

function* cartSaga() {
  yield takeLatest(GET_INFO_DZONE_CART_INFO_C1_SAGA, getCartInfoHandler);
  yield takeLatest(FETCH_CART_INFO_SAGA, fetchCartInfoSaga);
  yield takeLatest(GET_INFO_DZONE_CART_INFO_DEL_C1_SAGA, getCartInfoDeleteHandler);
  yield takeLatest(GET_INFO_DZONE_CART_INFO_USER_C2_SAGA, getCartInfoUserHandler);
  yield takeLatest(CHECKOUT_CART_SAGA, postCartHandler);
  yield takeLatest(C2_GET_DELIVERY_DATE_LIST, getDeliveryListHandler);
  yield takeLatest(GET_ITEM_POSTAGE_SAGA, itemPostage);
  yield takeLatest(GET_ORDER_API, orderDetails);
  yield takeLatest(UPDATE_ORDER_API, updateOrderDetails);
  yield takeLatest(DELETE_CART_API, chackAndDeleteCart);
}

export default cartSaga;
