import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export default function CartAlertDialog(props) {
  let primaryText = props.primaryText ?? "同一カテゴリの部品が複数含まれています";
  let secondaryText = props.secondaryText ?? "誤発注防止の為、確認の上で購入へとお進みください";

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-alert"
      >
        <DialogContent sx={{ padding: 0 }}>
          <Typography
            variant="p"
            component="p"
            className="font-size-18 mt-4 mb-2 mx-5 font-weight-700"
          >
            {primaryText}
          </Typography>
          <Typography variant="p" component="p" className="font-size-14 mt-2 mb-3 mx-5">
            {secondaryText}
          </Typography>
          <hr></hr>
          <Typography
            variant="p"
            component="p"
            align="center"
            onClick={props.handleClose}
            className="font-size-14 mt-1 mb-3 font-color-orange cursor"
          >
            確認する
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
