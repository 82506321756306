import {
  GET_NOTICE_INFO_API,
  UPDATE_NOTICE_READ_STATUS_API,
  GET_NOTICE_READ_COUNT_API,
} from "../../utils/apiConstants";
import instance from "../../utils/AxiosIntance";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import { CMS_NEWS_API } from "../../constants/envs";

export function noticeNewApiCall(payload) {
  return axios
    .create({
      headers: {
        "X-MICROCMS-API-KEY": "74b2ecc1c7da4ce6b1a093fe8475128881b3",
      },
    })
    .get(CMS_NEWS_API, {
      params: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function noticeApiCall(payload) {
  return instance
    .get(GET_NOTICE_INFO_API, {
      params: payload,
    })
    .then((response) => {
      return {
        type: "NOTICE",
        data: response.data.data,
        result: response.data.result,
      };
    })
    .catch((error) => {
      toastr.error("", _.get(error, "response.data.message"));
      return { type: "NOTICE", result: false };
    });
}

export function noticeReadStatusUpdateApiCall(payload) {
  return instance
    .put(UPDATE_NOTICE_READ_STATUS_API, payload)
    .then((response) => {
      return { type: "NOTICE", result: response.data.result };
    })
    .catch((error) => {
      toastr.error("", _.get(error, "response.data.message"));
      return { type: "NOTICE", result: false };
    });
}

export function noticeReadCountApiCall() {
  return instance
    .get(GET_NOTICE_READ_COUNT_API)
    .then((response) => {
      return { type: "NOTICE", response: response };
    })
    .catch((error) => {
      toastr.error("", _.get(error, "response.data.message"));
      return { type: "NOTICE", result: false };
    });
}
