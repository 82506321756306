import { Grid, Stack, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { OrangeButton, OrangeLinkButton } from "../PhaseBStyles/style";
import CartPolicyDialog from "../Cart/CartPolicyDialog";
import { useState } from "react";

export default function CartPrice(props) {
  const [open, setOpen] = useState(false);
  const btnTitle = props.btnTitle;
  const showCheckoutText = props.showCheckoutText ?? false;
  let { total_sales_price, total_shipping_rates, total_sales_tax, total_price } = props.cartAmount;
  const isCheckoutButtonDisabled = props.isCheckoutButtonDisabled;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const numberWithCommas = (x) => {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <CartPolicyDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div>
          <span className="font-size-14">小計</span>
        </div>
        <div>
          <span className="font-size-16">{numberWithCommas(total_sales_price.toFixed(0))}</span>
          <span className="font-size-12 opacity-80"> 円 (税抜)</span>
        </div>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1}>
        <div>
          <span className="font-size-14">送料</span>
        </div>
        <div>
          <span className="font-size-16">{numberWithCommas(total_shipping_rates.toFixed(0))}</span>
          <span className="font-size-12 opacity-80"> 円 (税抜)</span>
        </div>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1}>
        <div>
          <span className="font-size-14">消費税</span>
        </div>
        <div>
          <span className="font-size-16">{numberWithCommas(total_sales_tax.toFixed(0))}</span>
          <span className="font-size-12 opacity-80"> 円</span>
        </div>
      </Stack>
      <hr></hr>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div>
          <span className="font-size-14">合計</span>
        </div>
        <div>
          <span className="font-size-18">{numberWithCommas(total_price.toFixed(0))}</span>
          <span className="font-size-12 opacity-80"> 円 (税込)</span>
        </div>
      </Stack>
      <div className="d-none d-md-block">
        <OrangeButton
          variant="contained"
          fullWidth
          className="mt-3"
          onClick={props.nextPage}
          disabled={isCheckoutButtonDisabled}
        >
          <span className="font-size-16 font-weight-700">{btnTitle}</span>
        </OrangeButton>
        <div className={showCheckoutText ? "d-block" : "d-none"}>
          <p className="mt-3 font-size-12 opacity-80">
            購入手続き後、すでにUZone以外で販売済みとなってしまい商品を確保できない場合がございます。
            欠品がある場合、メールにてご連絡致します。
          </p>
        </div>
        <OrangeLinkButton
          disableRipple
          fullWidth
          className="font-weight-400 font-size-14 opacity-80 text-center cursor"
          onClick={() => {
            setOpen(true);
          }}
        >
          返品、返金について
        </OrangeLinkButton>
      </div>
    </>
  );
}
